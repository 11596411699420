export enum FORM_FIELDS {
  NAME = "name",
  NAME_LABEL = "Name",
  LEGAL_NAME = "Legal Entity Name",
  CITY = "city",
  CITY_LABEL = "City",
  CONSULTING_CITY_LABEL = "Consulting Cities",
  HIGH_STREET = "highStreet",
  HIGH_STREET_LABEL = "HighStreet/Market",
  SHOP_TYPE = "shopType",
  SHOP_TYPE_LABEL = "Shop Type",
  DESIGNATION = "designation",
  LL_TYPE = "LandLordType",
  LL_TYPE_LABEL = "Landlord Type",
  LL = "Landlord",
  LL_ID = "LL_ID",
  DESIGNATION_LABEL = "Designation",
  EMAIL = "email",
  EMAIL_LABEL = "Email",
  BRAND = "brand",
  BRAND_LABEL = "Brand",
  HEIGHT = "height",
  HEIGHT_LABEL = "Height",
  FRONTAGE = "frontage",
  FRONTAGE_LABEL = "Frontage",
  MIN_CARPET_AREA = "minCarpetArea",
  MIN_CARPET_AREA_LABEL = "Min total carpet area",
  MAX_CARPET_AREA_LABEL = "Max total carpet area",
  MAX_CARPET_AREA = "maxCarpetArea",
  MIN_FLOOR_PLATE = "minFloorPlate",
  MIN_FLOOR_PLATE_LABEL = "Min floor plate area",
  REMARKS = "remarks",
  REMARKS_LABEL = "Remarks (Optional)",
  PHONE_NUMBER = "phoneNumber",
  PHONE_NUMBER_LABEL = "Phone Number",
  FLOORS = "Floors",
  CARPET_AREA = "Carpet Area",
  INDUSTRY = "industry",
  INDUSTRY_LABEL = "Industry",
  BRAND_TYPE = "Brand Type",
  BRAND_NAME = "brandName",
  BRAND_NAME_LABEL = "Brand Name",
  LEGAL_ENTITY = "legalEntity",
  LEGAL_ENTITY_LABEL = "Legal Entity",
  STATUS = "Status",
  AREA = "Area",
  RATE_PER_SF = "Rate/sq.ft(Optional)",
  RATE_PER_SF_LABEL = "Rate/sq.ft",
  FINANCIAL_YEAR = "Financial year",
  LL_BROKERAGE = "LL Brokerage",
  BRAND_BROKERAGE = "Brand Brokerage",
  MONTH = "Month",
  TOTAL_BROKERAGE = "Total Brokerage",
  OPTIONAL = " (optional)",
  MALL_LOCATION = "mallLocation",
  MALL_LOCATION_LABEL = "Enter Mall location/address",
  MALL_NAME = "mallName",
  MALL_NAME_LABEL = "Enter Mall Name",
  SHOP_NO = "Shop Number",
  SHOP_ADDRESS = "Shop Address",
  PINCODE = "Pincode",
  DEAL_TYPE = "dealType",
  DEAL_TYPE_LABEL = "Deal Type",
  BILLING_CLIENT_LABEL = "Billing Client",
  BILLING_CLIENT = "BillingClient",
  WIP_TYPE = "wipType",
  WIP_TYPE_LABEL = "WIP Type",
  MALL = "Mall",
  BILLING_ADDRESS = "billingAddress",
  BILLING_ADDRESS_LABEL = "Client's Billing Address",
  STATE_NAME = "stateName",
  CITY_NAME = "cityName",
  SHIPPING_ADDRESS = "shippingAddress",
  SHIPPING_PINCODE = "shippingPincode",
  SHIPPING_STATE_NAME = "shippingStateName",
  SHIPPING_CITY_NAME = "shippingCityName",
  GST = "gst",
  GST_LABEL = "Client's GST number",
  POC_NAME = "POC Name",
  POC_DESIGNATION = "POC Designation",
  POC_NUMBER = "POC Number",
  POC_EMAIL = "POC Email",
  LESSOR_NAME = "lessorName",
  ANAROCK_POC = "anarockPOC",
  AR_POC_NUMBER = "arPocNumber",
  RENT = "rent",
  SERVICE_TYPE = "service_type",
  SRVC_DESCRIPTION_LABEL = "Service Description (Optional)",
  DESCRIPTION = "description",
  FEE = "fee",
  INVOICE_AMT = "invoiceAmt",
  CREDIT_PERIOD = "Credit Period",
  CREDIT_RATINGS = "creditRating",
  CREDIT_RATINGS_LABEL = "Client’s Credit Ratings",
  FEE_WORDS = "feeWords",
  TP_NAME = "tpName",
  TP_NAME_LABEL = "Third Party Name",
  TP_SHARE_LABEL = "Third Party Revenue Share",
  TP_SHARE = "tpShare",
  EMP_NAME = "employeeName",
  EMP_SHARE = "employeeShare",
  GROUP_NAME = "groupName",
  GROUP_NAME_LABEL = "Group Name (Optional)",
  TOTAL_INVOICABLE_BROKERAGE = "Total Invoiceable Brokerage",
  INVOICING_AMT_WITH_TAX = "Invoicing amount (With 18% TAX)",
  CHEQUE_NO = "Cheque Number",
  TOTAL_AMT_RECIEVED = "Total Amount Received",
  AMT_RECIEVED = "Amount Received",
  TDS_RECIEVED = "TDS Received",
  INVOICE_NO = "Invoice no.",
  COMMENTS = "Comment/remarks",
  PROFIT_CENTER = "Profit Center",
  PROFIT_CENTER_LABEL = "Profit Center",
  BRAND_LEGAL_NAME = "Brand Legal Name",
  BRAND_CATEGORY = "Brand Category",
  CITY_N_STATE_NAME = "City & State",
  SHIPPING_CITY_N_STATE_NAME = "Shipping City & State",
  SERVICE_FEES_BASE = "Service fee calculation base",
  LS_AMOUNT = "Lump sum amount",
  SEC_DEP_MULTIPLE = "Service fee as % of security",
  NO_OF_DAYS = "No of days",
  NO_OF_DAYS_FOR_BROKERAGE = "No of days for brokerage",
  NO_OF_YRS = "No of years",
  NO_OF_MONTHS = "No of month (s)",
  SEC_DEP_AMT = "Security deposit amount",
  SERVICE_FEE = "Service Fee amount (Without TAX)",
  PA_AS_PER_AGGREEMENT = "Property Area (As per agreement)",
  TYPE_OF_SERVICE = "Type of Service",
  MIN_RENT_LABEL = "Minimum guarantee rent/ month",
  RENT_LABEL = "Monthly rent",
  CLIENT_GST = "Client’s GST",
  STATE = "State",
  CLIENT_POC_NAME = "Client’s POC name",
  CLIENT_POC_DESIGNATION = "Client POC designation",
  CLIENT_POC_NO = "Client’s POC No.",
  CLIENT_POC_EMAIL = "Client’s POC Email ID",
  LESSOR_NAME_LABEL = "Lessor/Lessee Name (Optional)",
  LESSOR_NAME_LABEL_REQ = "Lessor/Lessee Name",
  PROPERTY_STATE = "Property location (State)",
  ANAROCK_POC_FOR_BILLING = "Anarock POC for billing",
  ANAROCK_POC_NAME = "Anarock POC name",
  ANAROCK_POC_NO = "Anarock POC No.",
  LESSOR_LL = "Lessor/Lessee Name",
  LESSE_BRAND = "Lessee/Brand Name",
  CLAIM_TYPE = "Claim Type",
  PAYMENT_TYPE = "Payment Type",
  UTR_NO = "UTR Number",
  POC = "poc",
  POC_LABEL = "Client's POC",
  BRAND_POC_LABEL = "Brand POC name",
  SHOP_ID = "Shop ID",
  CLIENT_EMAIL = "Client's email ID",
  IDENTITY_PROOF = "Identity Proof",
  PAN_NO = "PAN number",
  AADHAR_NO = "Aadhaar number",
  RESI_ADDRESS = "Resident address",
  AUTH_SIGN = "Authorised signatory",
  FEE_BASE_1 = "Fee bases 1",
  FEE_BASE_2 = "Fee bases 2",
  TDS_CLAIMED = "TDS",
  TDS_CLAIMED_LABEL = "TDS being claimed",
  REG_NO = "Registration number",
  CIN_NO = "CIN number",
  FATHERS_NAME = "Father’s name",
  MONTHLY_COMP = "Monthly compensation",
  // YEARLY_AVG_RENT = "Yearly average rent",
  MONTHLY_AVG_RENT = "Monthly average rent",
  SHOP_DETAILS_AVLB = "Shop details availability",
  LL_DETAILS_AVLBL = "Landlord details availability",
  TP_INVOLVE = "Third party involvement",
  CN_TYPE = "Credit note type",
  CN_REASON = "Reason for credit note request",
  ORIGINAL_FEE = "Original Fee (Without TAX)",
  CREDIT_NOTE_FEE = "Credit note (Without TAX)",
  NEW_FEE = "New Fee (Without TAX)",
  UPDATED_FEE = "Updated Fee (Without TAX)",
  REV_REC_MONTH = "Revenue recognition month",
}

export enum FORM_MODAL_STRING {
  DONE = "Done",
  SELECT_CITY = "Select City",
  SELECT_BRAND = "Select Brand",
  SELECT_HIGH_STREET = "Select High Street/Market",
  SELECT_SHOP_TYPE = "Select Shop type",
  SEARCH_FOR_CITY = "Search for City",
  SEARCH_FOR_BRAND = "Search for Brand",
  SEARCH_FOR_SHOP_TYPE = "Search for shop type",
  SEARCH_FOR_MARKETS = "Search for Markets",
  SELECT_FLOORS_IN = "Select floors in",
  SEARCH_FOR_FLOORS = "Search for Floors",
  SELECT_INDUSTRY = "Select Industry",
  SEARCH_INDUSTRY = "Search for Industry",
  SELECT_DESIGNATION = "Select Designation",
  SELECT_FY = "Select Financial year",
  SELECT_MONTH = "Select Month",
  SELECT_LL = "Select Landlord Type",
  CHOOSE_MALL = "Choose Mall",
  SEARCH_FOR_MALL = "Search for Mall",
  SELECT_INVOICE = "Select Invoice No.",
  SELECT_PROFIT_CENTER = "Select Profit center",
  SEARCH_FOR_PROFIT_CENTER = "Search for Profit center",
  CHOOSE_GST = "Choose client's GST no.",
  SEARCH_FOR_GST = "Search for GST no.",
  CHOOSE_POC = "Choose client's POC",
  SEARCH_FOR_POC = "Search for POC",
  SELECT_BRAND_POC = "Select Brand POC",
  CHOOSE_BILLING_ADDRESS = "Choose Billing Address",
  CHOOSE_BILLING_ADDRESS_FOR = "Choose Billing Address For",
  SEARCH_FOR_BILLING_ADDRESS = "Search for billing address",
  FEE_BASE_1 = "Fee bases 1",
  FEE_BASE_2 = "Fee bases 2",
}
