import { ArButton, ArInput, ArInputType, ArModal, ButtonTypes, varientType } from "@anarock/ar-common-react";
import { StopOutlined } from "@ant-design/icons";
import { useUpdateConsultingWipDetailsMutation } from "app/services/consultingWip";
import { useUpdateWipDetailsMutation } from "app/services/wip";
import { useToastService } from "components/toastService";
import { WIP_APPROVAL_STATUS } from "constants/index";
import { STRING_CONSTANTS } from "constants/StringConstants";
import { TOAST_MESSAGES } from "constants/ToastMessages";
import uploadStyles from "features/invoices/components/UploadInvoiceModal/index.module.scss";
import { StaticValue } from "features/wip/components/WIP-BrokerageProjectionForm/partials";
import React, { useState } from "react";

import { DropWIPModalProps } from "./index.interface";

const DropWIPModal = ({
  clientName,
  currentStatus,
  closeModal,
  isConsulting,
  projectId,
  approval,
  ...props
}: DropWIPModalProps) => {
  const [comment, setComment] = useState("");
  const [updateWIP, { isLoading }] = useUpdateWipDetailsMutation();
  const [updateConsultingWIP, { isLoading: isConsultingLoading }] = useUpdateConsultingWipDetailsMutation();

  const { errorToast } = useToastService();

  const handleDrop = async () => {
    let res;
    try {
      if (isConsulting) {
        res = await updateConsultingWIP({
          projectId,
          approval: {
            ...approval,
            approvalStatus: WIP_APPROVAL_STATUS.DROPPED,
            comment,
          },
        }).unwrap();
      } else {
        res = await updateWIP({
          projectId,
          approval: {
            ...approval,
            approvalStatus: WIP_APPROVAL_STATUS.DROPPED,
            comment,
          },
        }).unwrap();
      }
    } catch (e) {
      errorToast(TOAST_MESSAGES.WIP_SAVED_ERROR);
    }
    if (!res?.success) {
      errorToast(TOAST_MESSAGES.WIP_SAVED_ERROR);
    } else {
      closeModal?.();
      errorToast("Dropping Deal is completed");
    }
  };
  const footer = (
    <div className="modal-btn-container">
      <ArButton type={ButtonTypes.OutlinedApprove} onClick={closeModal}>
        {STRING_CONSTANTS.DONT_DROP_WIP}
      </ArButton>
      <ArButton type={ButtonTypes.Reject} onClick={handleDrop} loading={isLoading || isConsultingLoading}>
        {STRING_CONSTANTS.YES_DROP_WIP}
      </ArButton>
    </div>
  );

  return (
    <ArModal width={946} title={`Dropping WIP for ${clientName}`} showBorderForHeader footer={footer} {...props}>
      <div className={uploadStyles.importSheetModal}>
        <StopOutlined className={uploadStyles.uploadIcon} />
        <h5 className="hero-text pb-16">{STRING_CONSTANTS.DROP_WIP_HEADING}</h5>
        <div className="pb-32">
          <p>{STRING_CONSTANTS.DROP_WIP_DES_1}</p>
          <p> {STRING_CONSTANTS.DROP_WIP_DES_2}</p>
          <p> {STRING_CONSTANTS.DROP_WIP_DES_3}</p>
        </div>
        <div className={`${uploadStyles.uploadContainer} display-flex-space-between`}>
          <div className="pt-16">
            <StaticValue label={STRING_CONSTANTS.CURRENT_WIP_STATUS} value={currentStatus || ""}></StaticValue>
          </div>
          <div>
            <ArInput
              type={ArInputType.text}
              label={STRING_CONSTANTS.REASON_FOR_DROPPING}
              required
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              varient={varientType.default}
            ></ArInput>
          </div>
        </div>
      </div>
    </ArModal>
  );
};

export default DropWIPModal;
