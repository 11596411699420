import { Rule } from "antd/es/form";
import { FORM_FIELDS } from "constants/RequirementFrom";
import { STRING_CONSTANTS } from "constants/StringConstants";
import Errors from "constants/ValidationErrors";

export const requiredRulesOnChange: Rule[] = [
  {
    validator: (_, value) => {
      if (value) {
        return Promise.resolve();
      }
      return Promise.reject(new Error(Errors.REQUIRED_FIELD));
    },
    validateTrigger: "onChange",
  },
];

export const requiredRulesOnBlur: Rule[] = [
  {
    validator: (_, value) => {
      if (value) {
        return Promise.resolve();
      }
      return Promise.reject(new Error(Errors.REQUIRED_FIELD));
    },
    validateTrigger: "onBlur",
  },
];

export const characterLimitRules = (min: number, max: number) => {
  return [
    {
      min,
      message: `${STRING_CONSTANTS.ENTER_AT_LEAST} ${min} ${STRING_CONSTANTS.CHARACTERS}`,
    },
    {
      max,
      message: `${STRING_CONSTANTS.ENTER_UPTO} ${max} ${STRING_CONSTANTS.CHARACTERS}`,
    },
  ];
};
export const numberRules: Rule[] = [
  {
    pattern: /\d+/,
    message: STRING_CONSTANTS.ENTER_VALID_WHOLE_NUMBER,
  },
];
export const numberRulesRequired: Rule[] = [{ required: true, message: Errors.REQUIRED_FIELD }, ...numberRules];

export const emailRules: Rule[] = [
  { required: true, message: STRING_CONSTANTS.ENTER_VALID_EMAIL },
  {
    type: FORM_FIELDS.EMAIL,
    message: STRING_CONSTANTS.ENTER_VALID_EMAIL,
  },
];

export const phoneRules: Rule[] = [
  {
    pattern: /^[6-9][0-9]{9}$/,
    message: Errors.INVALID_PHONE_NUMBER,
  },
];

export const minMaxCheckRule: Rule = ({ getFieldValue }) => ({
  validator(_, value) {
    if (!value) {
      return Promise.reject();
    }
    const minCarpetArea = getFieldValue(FORM_FIELDS.MIN_CARPET_AREA);
    if (Number(minCarpetArea) > Number(value)) {
      return Promise.reject(new Error(STRING_CONSTANTS.MIN_CARPET_AREA_GERATER_THAN_MAX_CARPET_AREA));
    }
    return Promise.resolve();
  },
  validateTrigger: "onSubmit",
});

export const pincodeRule: Rule = {
  validator: (_, value) => {
    if (value && String(value)?.length === 6) {
      return Promise.resolve();
    }
    return Promise.reject(new Error(Errors.INVALID_PINCODE));
  },
  validateTrigger: "onBlur",
};

export const gstNumberRule: Rule = {
  validator: (_, value) => {
    const gstinRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;

    if (value && gstinRegex.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error(Errors.INVALID_GST));
  },
  validateTrigger: "onBlur",
};

export const panNumberRule: Rule = {
  validator: (_, value: string) => {
    const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
    if (value && panRegex.test(value.toUpperCase())) {
      return Promise.resolve();
    }
    return Promise.reject(new Error(Errors.INVALID_PAN));
  },
  validateTrigger: "onBlur",
};
export const valueHundredRule: Rule = {
  validator: (_, value) => {
    if (value && Number(value) > 100) {
      return Promise.reject(new Error(Errors.LESS_THAN_100));
    }
    return Promise.resolve();
  },
  validateTrigger: "onBlur",
};
