export enum AUTH_URLS {
  LOGIN = "auth/login",
  LOGOUT = "auth/logout",
  CHECK_AUTH = "auth/check-auth",
  VERIFY_USER = "auth/verify-user",
  FORGOT_PASSWORD = "auth/password/forgot",
  RESET_PASSWORD = "auth/password/reset",
}

export enum MANAGE_REQUIREMENT_URLS {
  GET_BRAND_LIST = "brand/list",
  GET_FLOOR_LIST = "brand/floors",
  GET_SHOP_TYPE_LIST = "brand/shop-types",
  GET_CITY_LIST = "geo/cities",
  GET_ALL_CITY_LIST = "geo/cities/all",
  GET_LOCALITY_LIST = "geo/localities",
  CREATE_REQUIREMENT = "brand/requirement",
  DEACTIVATE_REQ = "/brand/requirement-detail/active",
  GET_REQ_DETAIL = "brand/requirement/",
  DETAILS = "/details",
}

export enum LISTING_URLS {
  GET_FILTERS = "brand/requirement/filters",
  GET_REQUIREMENT_LIST = "brand/requirement/list",
  GET_REQUIREMENT_LIST_V2 = "brand/v2/requirement/list",
  GET_TOTAL_REQ_COUNT = "/brand/requirement-detail/count",
}

export enum NUDGE_URLS {
  GET_NUDGE_DATA = "nudge",
  READ_NUDGE_DATA = "nudge/receipt",
}

export enum KYC_URLS {
  EKYC = "/e-kyc",
}

export enum MATCHING_PROPERTIES_URLS {
  BRAND_REQ = "brand/requirement-detail/",
  MATCH_LIST = "/shop/match/list",
  ADD_SHOPS = "/shop/option",
  NEARBY_MATCH_LIST = "/shop/nearby-match/list",
}

export enum OPTION_LIST_URLS {
  BRAND_REQ = "brand/requirement-detail/",
  GET_OPTION_LIST = "/shop/option/list",
  DELETE_OPTIONS = "brand/requirement-detail/shop/option",
  UPDATE_STATUS_OF_SHOPS = "brand/requirement-detail/shop/option/status",
  GENERATE_BROCHURE = "/generate-brochure",
  JOB = "job/",
  GENERATE_ZIP = "/download/files",
}

export enum SHOP_URLS {
  SHOP = "shop/",
  STATUS = "status",
  SHOP_STATUS_ENUMS = "shop/status-enum",
  HISTORY = "/history",
  SHOP_FILTERS = "shop/filters",
  FILTERED_SHOPS = "shop/list",
  MATCHING_REQUIREMENT_DETAILS = "requirement-details/match/list",
  ADD_TO_MULTIPLE_OPTIONS_LIST = "/brand/option",
  NEW_SHOP_COUNT = "shop/count",
}

export enum NOTIFICATION_URLS {
  NOTIFICATIONS = "notifications",
  READ = "read",
  USER_REQUEST = "user-request",
  ASK_TO_UPDATE = "user-request/shop/status-update",
  ASK_TO_UPDATE_DRAFT_SHOP = "user-request/shop",
  DRAFT_UPDATE = "draft-update",
  REQUEST_PROPERTIES = "user-request/requirement-detail",
  ADD_PROPERTIES = "add-shops",
}

export enum ADD_BRAND_URLS {
  INDUSTRY_ENUMS = "brand/industry",
  GET_SIGNED_URL = "brand/logo/upload-url",
  ADD_BRAND = "brand",
}

export enum USER_ANALYTICS {
  GET_USER_ANALYTICS = "user/analytics",
  MOST_INTERACTED = "user/requirement-detail/most-interacted",
  GET_REQUIREMENT_FILTER = "overview/requirement/filters",
  GET_LOCALITY_FILTERS = "overview/shop/filters",
  GET_FILTERED_SHOPS = "overview/shop/list",
}

export enum MARKER_URLS {
  GET_CITY_LIST = "geo/marker/cities",
  GET_LOCALITY_LIST = "geo/marker/localities",
  GET_SHOPS = "geo/marker/shops",
}

export enum ANALYTICS_URLS {
  ANALYTICS = `/analytics`,
  FILTERS = `/filters`,
  ANALYTICS_FILTERS = `/analytics/surveyors/filters`,
  ANALYTICS_TOTAL = `/analytics/surveyors/totals`,
  ANALYTICS_USER_TABLE = `/analytics/surveyors/users/table`,
  ANALYTICS_REGIONS = `/analytics/surveyors/regions`,
  ANALYTICS_USERS = `/analytics/surveyors/users`,
  AGENT_ANALYTICS_USERS = `/analytics/requirements/users`,
  AGENT_ANALYTICS_TOTALS = `/analytics/requirements/totals`,
  REQUIREMENT_TABLE = `/analytics/requirements/data`,
  AGENT_ANALYTICS_REGION = `/analytics/requirements/regions`,
  WIP_LIST = `/wip/list`,
  WIP_ANALYTICS_REGION = `/analytics/wip/regions`,
  WIP_ANALYTICS_AGENT = `/analytics/wip/users`,
  WIP_ANALYTICS_TOTALS = `/analytics/wip/totals`,
  WIP_SUMMARY = `/analytics/wip/summary`,
  WIP = "/wip",
  WIP_SCORECARD = "/scorecard",
  WIP_DETAILS = "/details",
  COLLECTIONS_ANALYTICS_TOTAL = "/analytics/wip/invoice/totals",
  INVOICE_LIST = "/wip/invoice/list",
  WIP_TOTAL_COUNT = "/wip/count",
  INVOICE_TOTAL_COUNT = "/wip/invoice/count",
  WIP_AUTO_SUGGEST = "wip/search/auto-suggest",
  INVOICE_AUTO_SUGGEST = "wip/invoice/search/auto-suggest",
  CONSULTING_WIP_AUTO_SUGGEST = "/wip/consulting/search/auto-suggest",
}

export enum INVOICES_URLS {
  INVOICE = "/wip/invoice/request",
  FILTERS = "/filters",
  INVOICE_REQUEST = "/wip/invoice/request",
  WIP_PROJECT = "/wip/project/",
  CLIENT = "/client",
  LIST = "/list",
  EXPORT = "/export",
  DOCUMENTS = "documents",
  UPLOAD_URL = "upload-url",
  WIP_INVOICE = "/wip/invoice",
  CREATE_INVOICE = "create-invoice",
  CLIENT_GST = "/client/gst/",
  INVOICE_SEARCH_AUTO_SUGGEST = "/wip/invoice/request/search/auto-suggest",
  COMMENT = "comment",
  COMMENTS = "comments",
  GEO_STATES_ALL = "/geo/states/all",
  GET_GST = "/client/gst",
  PAN = "/pan",
  POC = "/poc",
  GST = "/gst",
  PENDING_LIST = "/pending/list",
  CREDIT_NOTE = "/credit-note",
  INVOICE_REQUEST_COMMENT = "/invoice-request",
}

export enum REPORTS_URLS {
  REPORTS = "/wip/revenue/report",
  FILTERS = "/filters",
  EXPORT = "/export",
}

export enum KYC_URLS {
  PROJECT = "/project",
  KYC = "/kyc",
  CLIENT = "/client",
  LIST = "/list",
  ADDRESS = "/address",
  GST = "/gst",
  PAN = "/pan",
}

export enum PAYMENTS_URLS {
  PAYMENT = "/wip/payment/claim",
  FILTERS = "/filters",
  INVOICE_LIST = "/wip/invoice/outstanding",
  LIST = "/list",
  COMMENT = "/comment",
  PAYMENTS_CLAIM = "/payment-claim",
  AUTO_SUGGEST = "/wip/payment/claim/search/auto-suggest",
  GET_PAYMENT_BY_ID = "/wip/payment/claim/",
  GET_SIGNED_URL = "/wip/payment/claim/upload-url",
}

export enum CONSULTING_WIP_URLS {
  COMMENT = "/comment",
  CONSULTING_WIP = "/consulting-wip",
  WIP_CONSULTING_LIST = "/wip/consulting/list",
  WIP_CONSULTING = "/wip/consulting",
  FILTERS = "/filters",
  INVOICE = "/invoice",
  INVOICES = "/invoices",
  PROJECT = "/project",
  WIP_CONSULTING_PROJECT = "/wip/consulting/project/",
}

export enum INTEL_DATA_URLS {
  INTEL_DATA = "/intel-data",
  MARKET_BENCHMARKING = "/market-benchmarking",
  FILTERS = "/filters",
  COMMENT = "/comment",
  LIST = "/list",
}

export class DynamicApiUrls {
  matchingBrands(shopId: string): string {
    return `${SHOP_URLS.SHOP}${shopId}/${SHOP_URLS.MATCHING_REQUIREMENT_DETAILS}`;
  }
  getReqDetails(id: string): string {
    return `${MANAGE_REQUIREMENT_URLS.GET_REQ_DETAIL}${id}`;
  }
  addShopToMultipleOptionList(id: string): string {
    return `${SHOP_URLS.SHOP}${id}${SHOP_URLS.ADD_TO_MULTIPLE_OPTIONS_LIST}`;
  }
  selfUpdateShopStatus(id: string): string {
    return `${SHOP_URLS.SHOP}${id}/${SHOP_URLS.STATUS}`;
  }
  editRequirement(id: string): string {
    return `${MANAGE_REQUIREMENT_URLS.CREATE_REQUIREMENT}/${id}`;
  }
  generateBrochure(id: string): string {
    return `${OPTION_LIST_URLS.BRAND_REQ}${id}${OPTION_LIST_URLS.GENERATE_BROCHURE}`;
  }
  getJobStatus(id: string): string {
    return `${OPTION_LIST_URLS.JOB}${id}`;
  }
  generateBrochureForShop(id: string): string {
    return `${SHOP_URLS.SHOP}${id}${OPTION_LIST_URLS.GENERATE_BROCHURE}`;
  }
  markNotificationAsRead(id: string): string {
    return `${NOTIFICATION_URLS.NOTIFICATIONS}/${id}/${NOTIFICATION_URLS.READ}`;
  }
  requestProperties(id: string): string {
    return `${NOTIFICATION_URLS.REQUEST_PROPERTIES}/${id}/${NOTIFICATION_URLS.ADD_PROPERTIES}`;
  }
  askToUpdateDraftShop(id: string): string {
    return `${NOTIFICATION_URLS.ASK_TO_UPDATE_DRAFT_SHOP}/${id}/${NOTIFICATION_URLS.DRAFT_UPDATE}`;
  }
  generateZipForShop(id: string): string {
    return `${SHOP_URLS.SHOP}${id}${OPTION_LIST_URLS.GENERATE_ZIP}`;
  }
  reqDetailsWithFilters(id: string): string {
    return `${MANAGE_REQUIREMENT_URLS.GET_REQ_DETAIL}${id}${MANAGE_REQUIREMENT_URLS.DETAILS}`;
  }
}

export const DynamicApiUrlsInstance = new DynamicApiUrls();

export enum WIP_URLS {
  WIP = "/wip",
  INVOICE_PROJECT = "/invoice/project/",
  INVOICE = "/invoice",
  INVOICES = "/invoices",
  PROJECT = "/project",
  WIP_PROJECT = "wip/project/",
  COMMENT = "comment",
  COMMENTS = "comments",
  GET_MALL_LIST = "mall/city",
  MALL = "mall",
  INDEPENDENT = "/independent",
  EXPORT_WIP_SHEET = "wip/export",
  EXPORT_COLLECTION_SHEET = "wip/invoice/export",
  WIP_PROJECT_1 = "/wip/project/",
  LL_MALL = "landlord/mall/",
  LL = "landlord",
  DRAFT = "/draft",
}

export enum ENUM_URLS {
  ENUMS = "/enums",
}
export enum COLLECTION_URLS {
  COLLECTION = "/collection",
}

export enum PREVIEW_URLS {
  SHEET_UPLOAD = "sheet/upload-url",
  SHEET_VALIDATE = "/validate",
  SHEET = "sheet",
  SYNC = "/sync",
  EXPORT = "/export",
  INVOICE = "/invoice",
  BULK = "/bulk",
  ZIP = "/zip",
}
