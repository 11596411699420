import { ArDropdown, ArTabs } from "@anarock/ar-common-react";
import { ArFooter } from "@anarock/ar-common-react";
import { classNames } from "@anarock/ar-common-react/dist/utils/classNames";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { api } from "app/services/api";
import { useLogoutMutation } from "app/services/auth";
import { deleteFcmToken } from "app/utils/firebase";
import { getBillingRoutes, getHeaderForUserModules } from "app/utils/userHelperFunctions";
import HeaderLogo from "assets/images/anarockHeaderLogo.png";
import Logout from "assets/images/logout.png";
import { ReactComponent as Arrow } from "assets/images/small-arrow.svg";
import ViewContactDetailsModal from "components/appModals/viewContactDetailsModal";
import { useToastService } from "components/toastService";
import { APP_ROUTES } from "constants/Routes";
import { STRING_CONSTANTS } from "constants/StringConstants";
import { HEADER_TAB } from "constants/Taboptions";
import { TOAST_MESSAGES } from "constants/ToastMessages";
import AddToMultileOptionsList from "features/addToMultipleOptionsList";
import { logoutSuccess, selectAuthUser, selectUserDefaultRoute } from "features/authentication/authSlice";
import Notifications from "features/notifications";
import SelfUpdateShop from "features/selfUpdateShop";
import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import styles from "./index.module.scss";

export const Header = () => {
  const user = useAppSelector(selectAuthUser);

  const defaultRoute = useAppSelector(selectUserDefaultRoute);

  const headerItems = useMemo(() => {
    return getHeaderForUserModules(user?.modules, user?.role?.name);
  }, [user]);

  const { errorToast, successToast } = useToastService();
  const dispatch = useAppDispatch();
  const Navigate = useNavigate();
  const handleChange = (key: string) => {
    switch (key) {
      case HEADER_TAB.OVERVIEW:
        Navigate(APP_ROUTES.OVERVIEW);
        break;
      case HEADER_TAB.PROPERTIES:
        Navigate(`${APP_ROUTES.PROPERTIES}/${APP_ROUTES.PROPERTIES_NEW}`);
        break;
      case HEADER_TAB.REQUIREMENT:
        Navigate(APP_ROUTES.REQUIREMENT);
        break;
      case HEADER_TAB.MARKER:
        Navigate(APP_ROUTES.MARKER);
        break;
      case HEADER_TAB.ANALYTICS:
        break;
      case HEADER_TAB.REVENUE:
        break;
      case HEADER_TAB.BILLING:
        break;
      case HEADER_TAB.WIP_LISTING:
        break;
      case HEADER_TAB.COLLECTIONS_NEW:
        break;
      case HEADER_TAB.COLLECTIONS:
        Navigate(getBillingRoutes(APP_ROUTES.COLLECTIONS, user?.role?.name));
        break;
      case HEADER_TAB.INVOICES:
        Navigate(getBillingRoutes(APP_ROUTES.INVOICES, user?.role?.name));
        break;
      case HEADER_TAB.PAYMENTS:
        Navigate(getBillingRoutes(APP_ROUTES.PAYMENTS, user?.role?.name));
        break;
      case HEADER_TAB.INTEL_DATA:
        Navigate(APP_ROUTES.INTEL_DATA);
        break;
      default:
        Navigate(defaultRoute);
    }
  };
  const [logout] = useLogoutMutation();
  const handleLogout = async () => {
    let logoutResponse;
    try {
      logoutResponse = await logout().unwrap();
    } catch {
      errorToast(STRING_CONSTANTS.SOMETHING_WENT_WRONG);
    }
    if (logoutResponse?.success) {
      dispatch(logoutSuccess());
      deleteFcmToken();
      dispatch(api.util.resetApiState());
      Navigate(APP_ROUTES.LOGIN);
      successToast(TOAST_MESSAGES.USER_LOGGED_OUT_SUCCESSFULLY);
    } else {
      errorToast(logoutResponse?.reason || STRING_CONSTANTS.SOMETHING_WENT_WRONG);
    }
  };

  const handleClickLogo = () => {
    Navigate(defaultRoute);
  };

  return (
    <header className={styles.header}>
      <img loading="lazy" src={HeaderLogo} onClick={handleClickLogo} />
      <ArTabs
        activeKey={location.pathname.split("/")[1]}
        onTabClick={(key: string) => handleChange(key)}
        variant="anarock"
        onChange={(key: string) => handleChange(key)}
        items={headerItems}
      />
      <div className={styles.otherActions}>
        <Notifications />
        <img loading="lazy" onClick={handleLogout} src={Logout} className={styles.logout} />
      </div>
    </header>
  );
};
export const AnalyticsDropDown = ({
  showSurvAnalytics,
  showAgentAnalytics,
  hasAccessWIPAnalytics,
  hasAccessCollectionAnalytics,
}: {
  showSurvAnalytics?: boolean;
  showAgentAnalytics?: boolean;
  hasAccessWIPAnalytics?: boolean;
  hasAccessCollectionAnalytics?: boolean;
}) => {
  const [open, setOpen] = useState(false);

  const items = [
    showSurvAnalytics
      ? {
          key: "1",
          label: (
            <a href={`${APP_ROUTES.ANALYTICS}/${APP_ROUTES.SURVEYOR}`} className={styles.dropdownAnchor}>
              {STRING_CONSTANTS.SURVEYOR_ANALYTICS}
            </a>
          ),
        }
      : null,
    showAgentAnalytics
      ? {
          key: "2",
          label: (
            <a href={`${APP_ROUTES.ANALYTICS}/${APP_ROUTES.AGENT}`} className={styles.dropdownAnchor}>
              {STRING_CONSTANTS.AGENT_ANALYTICS}
            </a>
          ),
        }
      : null,
    hasAccessWIPAnalytics
      ? {
          key: "3",
          label: (
            <a href={`${APP_ROUTES.ANALYTICS}/${APP_ROUTES.WIP_ANALYTICS}`} className={styles.dropdownAnchor}>
              {STRING_CONSTANTS.WIP_ANALYTICS}
            </a>
          ),
        }
      : null,
    hasAccessCollectionAnalytics
      ? {
          key: "4",
          label: (
            <a href={`${APP_ROUTES.ANALYTICS}${APP_ROUTES.COLLECTIONS}`} className={styles.dropdownAnchor}>
              {STRING_CONSTANTS.COLLECTION_ANALYTICS}
            </a>
          ),
        }
      : null,
  ];

  return (
    <div>
      <ArDropdown
        menu={{
          items,
        }}
        mouseEnterDelay={0.3}
        onOpenChange={setOpen}
        open={open}
        overlayClassName={styles.dropdownOverlay}
      >
        <p className={styles.dropdownText}>
          {STRING_CONSTANTS.ANALYTICS} <Arrow {...classNames(styles.multiselectArrow, open && styles.rotate)} />
        </p>
      </ArDropdown>
    </div>
  );
};
export const Footer = () => {
  const footerText = `© ${new Date().getFullYear()} ANAROCK Property Consultant Pvt Ltd.`;
  return (
    <footer>
      <ArFooter className={styles.footer} copyright={footerText} />
    </footer>
  );
};
export const AppGlobalActionComponent: React.FC = () => {
  return (
    <>
      <ViewContactDetailsModal />
      <SelfUpdateShop />
      <AddToMultileOptionsList />
    </>
  );
};

export const BillingDropDown = ({
  showPayments,
  showInvoicesRequest,
  showCollections,
}: {
  showPayments?: boolean;
  showInvoicesRequest?: boolean;
  showCollections?: boolean;
}) => {
  const [open, setOpen] = useState(false);
  const user = useAppSelector(selectAuthUser);

  const items = [
    showInvoicesRequest
      ? {
          key: "1",
          label: (
            <a href={getBillingRoutes(APP_ROUTES.INVOICES, user?.role?.name)} className={styles.dropdownAnchor}>
              {HEADER_TAB.INVOICES_REQ_LABEL}
            </a>
          ),
        }
      : null,
    showCollections
      ? {
          key: "2",
          label: (
            <a href={getBillingRoutes(APP_ROUTES.COLLECTIONS, user?.role?.name)} className={styles.dropdownAnchor}>
              {HEADER_TAB.COLLECTIONS_LABEL}
            </a>
          ),
        }
      : null,
    showPayments
      ? {
          key: "3",
          label: (
            <a href={getBillingRoutes(APP_ROUTES.PAYMENTS, user?.role?.name)} className={styles.dropdownAnchor}>
              {HEADER_TAB.PAYMENTS_LABEL}
            </a>
          ),
        }
      : null,
  ];

  return (
    <div>
      <ArDropdown
        menu={{
          items,
        }}
        mouseEnterDelay={0.3}
        onOpenChange={setOpen}
        open={open}
        overlayClassName={styles.dropdownOverlay}
      >
        <p className={styles.dropdownText}>
          {HEADER_TAB.BILLING_LABEL} <Arrow {...classNames(styles.multiselectArrow, open && styles.rotate)} />
        </p>
      </ArDropdown>
    </div>
  );
};

export const WIPOptionsDropDown = () => {
  const [open, setOpen] = useState(false);

  const items = [
    {
      key: "1",
      label: (
        <a href={`${APP_ROUTES.WIP}/${APP_ROUTES.LISTING}`} className={styles.dropdownAnchor}>
          {HEADER_TAB.LEASING_LABEL}
        </a>
      ),
    },
    {
      key: "2",
      label: (
        <a href={`${APP_ROUTES.WIP}/${APP_ROUTES.CONSULTING}/${APP_ROUTES.LISTING}`} className={styles.dropdownAnchor}>
          {HEADER_TAB.CONSULTING_LABEL}
        </a>
      ),
    },
  ];

  return (
    <div>
      <ArDropdown
        menu={{
          items,
        }}
        mouseEnterDelay={0.3}
        onOpenChange={setOpen}
        open={open}
        overlayClassName={styles.dropdownOverlay}
      >
        <p className={styles.dropdownText}>
          {HEADER_TAB.WIP_LISTING_LABEL} <Arrow {...classNames(styles.multiselectArrow, open && styles.rotate)} />
        </p>
      </ArDropdown>
    </div>
  );
};

export const RevenueOptionsDropDown = () => {
  const [open, setOpen] = useState(false);

  const items = [
    {
      key: "1",
      label: (
        <a href={`${APP_ROUTES.REVENUE}${APP_ROUTES.INVOICES}/${APP_ROUTES.LISTING}`} className={styles.dropdownAnchor}>
          {HEADER_TAB.INVOICES_LABEL}
        </a>
      ),
    },
    {
      key: "2",
      label: (
        <a href={`${APP_ROUTES.REVENUE}${APP_ROUTES.REVENUE_REPORT}`} className={styles.dropdownAnchor}>
          {HEADER_TAB.REVENUE_REPORT}
        </a>
      ),
    },
  ];

  return (
    <div>
      <ArDropdown
        menu={{
          items,
        }}
        mouseEnterDelay={0.3}
        onOpenChange={setOpen}
        open={open}
        overlayClassName={styles.dropdownOverlay}
      >
        <p className={styles.dropdownText}>
          {HEADER_TAB.REVENUE_LABEL} <Arrow {...classNames(styles.multiselectArrow, open && styles.rotate)} />
        </p>
      </ArDropdown>
    </div>
  );
};

export const CollectionOptionsDropDown = () => {
  const [open, setOpen] = useState(false);

  const items = [
    {
      key: "1",
      label: (
        <a
          href={`/${HEADER_TAB.COLLECTIONS_NEW}${APP_ROUTES.COLLECTIONS}/${APP_ROUTES.LISTING}`}
          className={styles.dropdownAnchor}
        >
          {HEADER_TAB.COLLECTIONS_LABEL}
        </a>
      ),
    },
    {
      key: "2",
      label: (
        <a href={`/${HEADER_TAB.COLLECTIONS_NEW}${APP_ROUTES.COLLECTION_REPORT}`} className={styles.dropdownAnchor}>
          {HEADER_TAB.COLLECTION_REPORT}
        </a>
      ),
    },
  ];

  return (
    <div>
      <ArDropdown
        menu={{
          items,
        }}
        mouseEnterDelay={0.3}
        onOpenChange={setOpen}
        open={open}
        overlayClassName={styles.dropdownOverlay}
      >
        <p className={styles.dropdownText}>
          {HEADER_TAB.COLLECTIONS_NEW} <Arrow {...classNames(styles.multiselectArrow, open && styles.rotate)} />
        </p>
      </ArDropdown>
    </div>
  );
};
