export enum APP_ROUTES {
  DEFAULT = "/",
  LOGIN = "/login",
  CREATE_REQUIREMENT = "/create-requirement",
  REQUIREMENT = "/requirement",
  LOCALITY = "locality",
  OVERVIEW = "/overview",
  PROPERTIES = "/properties",
  MATCHING_PROPERTIES = "matchingProperties",
  DRAFT_PROPERTIES = "draftProperties",
  OPTION_LIST = "optionList",
  SHOP_INFO = ":shopId",
  REQ_DETAILS = ":reqId",
  EDIT_REQUIREMENT = ":reqId/edit",
  OVERVIEW_REQUIREMENTS = "requirements",
  NOTIFY = "/notify",
  MARKER = "/marker",
  CREATE_WIP = "/create",
  FORGOT_PASSWORD = "/forgot-password",
  RESET_PASSWORD = "/reset-password",
  WIP = "/wip",
  EDIT_WIP = "/edit",
  WIP_SUMMARY = "summary",
  WIP_DOCUMENTS = "documents",
  WIP_INVOICES = "invoices",
  WIP_CONTACT_DETAILS = "contact-details",
  WIP_COMMERCIAL_DETAILS = "commercial-details",
  WIP_BROKERAGE_DETAILS = "brokerage-details",
  ANALYTICS = "/analytics",
  SURVEYOR = "surveyor",
  AGENT = "agent",
  WIP_ANALYTICS = "wip",
  ANALYTICS_DETAILS = "details",
  PROPERTIES_NEW = "new",
  PROPERTIES_PREVIOUS = "previous",
  WIP_SCORECARD = "scorecard",
  LISTING = "listing",
  PREVIEW = "/preview",
  INVOICE = "/invoice",
  REVENUE = "/revenue",
  SHEET_ID = ":sheetId",
  COLLECTIONS = "/collections",
  ONBOARDING = "/onboarding",
  INVOICES = "/invoices",
  INVOICE_DETAILS = "/invoices/:invoiceNo",
  INSPECTION = "/inspection",
  BILLING = "/billing",
  PAYMENTS = "/payments",
  CONSULTING = "consulting",
  INTEL_DATA = "/intel-data",
  KYC = "/kyc",
  FEE_LETTER = "/fee-letter",
  REVENUE_REPORT = "/revenue-reoprt",
  COLLECTION_REPORT = "/collection-reoprt",
}
