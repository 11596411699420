import "../index.module.scss";

import { ArInputType, ArTag, inputType, nodeType, varientType } from "@anarock/ar-common-react";
import { optionProps } from "@anarock/ar-common-react/dist/ui-components/ar-checkbox/index.interface";
import { WarningOutlined } from "@ant-design/icons";
import { Rule } from "antd/es/form";
import { IEnum } from "app/services/enums";
import { IDocuments, IIRF } from "app/services/invoices";
import { isNullOrUndefiend, isNullOrUndefiendOrEmpty, isObjectNullOrUndefiendOrEmpty } from "app/utils/helperFunctions";
import { FEE_CALCULATION_BASE_1, PURE_REV_SHARING_OPTIONS, STAGGERED_RENT_OPTIONS } from "constants/index";
import { STRING_CONSTANTS } from "constants/StringConstants";
import ValidationErrors from "constants/ValidationErrors";
import React, { ReactNode } from "react";

export const RequiredTextNode = (
  fieldName: string,
  label: string,
  initialValue?: string,
  desc?: string,
  rules?: Rule[],
  inputClassName?: string,
  disabled?: boolean
) => ({
  type: nodeType.input,
  elementData: {
    inputType: inputType.text,
    formItemProps: {
      name: fieldName,
      rules: [{ required: true, message: ValidationErrors.REQUIRED_FIELD }, ...(rules || [])],
      initialValue: initialValue,
    },
    inputProps: {
      type: ArInputType.text,
      label: label,
      description: desc,
      varient: varientType.default,
      required: true,
      disabled,
      className: `inputBoxPadding ${inputClassName}`,
    },
  },
});
export const RequiredNumberNode = (
  fieldName: string,
  label: string,
  initialValue?: string,
  desc?: string,
  rules?: Rule[],
  suffix?: ReactNode,
  disabled?: boolean
) => ({
  type: nodeType.input,
  elementData: {
    inputType: inputType.text,
    body: <></>,
    formItemProps: {
      name: fieldName,
      rules: [{ required: true, message: ValidationErrors.REQUIRED_FIELD }, ...(rules || [])],
      initialValue: initialValue,
    },
    inputProps: {
      type: ArInputType.number,
      label: label,
      description: desc,
      disabled,
      varient: varientType.default,
      required: true,
      suffix: suffix,
    },
  },
});

export const DisabledTextNode = (
  fieldName: string,
  label: string,
  value?: string,
  desc?: string,
  required?: boolean
) => {
  const req = required === undefined ? true : required;
  return {
    type: nodeType.input,
    elementData: {
      inputType: inputType.text,
      formItemProps: {
        name: fieldName,
        rules: req ? [{ required: true, message: ValidationErrors.REQUIRED_FIELD }] : [],
        initialValue: value || "-",
      },
      inputProps: {
        type: ArInputType.text,
        label: label,
        varient: varientType.default,
        required: req,
        disabled: true,
        description: desc,
      },
    },
  };
};

export const TextNode = (fieldName: string, label: string, initialValue?: string, placeholder?: string) => ({
  type: nodeType.input,
  elementData: {
    inputType: inputType.text,
    formItemProps: {
      name: fieldName,
      initialValue,
    },
    inputProps: {
      type: ArInputType.text,
      label: label,
      placeholder: placeholder,
      varient: varientType.default,
    },
  },
});

export const EmptyNode = {
  nodeType: nodeType.container,
  type: nodeType.container,
  elementData: {
    body: <div className="pb-16"></div>,
  },
};

export const validateIRFForm = (irf?: IIRF, documents?: IDocuments[], isLead?: boolean, creditNoteEnum?: IEnum) => {
  if (
    isNullOrUndefiendOrEmpty(irf?.client?.legalEntityName) ||
    isNullOrUndefiendOrEmpty(irf?.client?.type) ||
    (irf?.kyc?.kycNumber?.length && irf?.kyc?.kycNumber?.length < 10) ||
    isNullOrUndefiendOrEmpty(irf?.kyc?.kycNumber) ||
    isNullOrUndefiendOrEmpty(irf?.agreementDetails?.credit?.period) ||
    isNullOrUndefiendOrEmpty(irf?.agreementDetails?.credit?.rating) ||
    isNullOrUndefiendOrEmpty(irf?.profitCenter)
  ) {
    return 1;
  }
  if (!isObjectNullOrUndefiendOrEmpty(irf?.shippingAddress)) {
    if (
      isNullOrUndefiendOrEmpty(irf?.shippingAddress?.address) ||
      isNullOrUndefiendOrEmpty(irf?.shippingAddress?.cityName) ||
      isNullOrUndefiendOrEmpty(irf?.shippingAddress?.pincode) ||
      isNullOrUndefiendOrEmpty(irf?.shippingAddress?.stateName)
    ) {
      return 1;
    }
  }
  //POC && other
  if (
    irf?.poc?.length === 0 ||
    isNullOrUndefiendOrEmpty(irf?.poc?.[0]?.name) ||
    isNullOrUndefiendOrEmpty(irf?.poc?.[0]?.phoneNumber?.number) ||
    isNullOrUndefiendOrEmpty(irf?.poc?.[0]?.email)
  ) {
    return 1;
  }
  // step 2 validaions
  // Agreement object

  //consulting case
  if (
    irf?.wipType === "CONSULTING" &&
    (isNullOrUndefiendOrEmpty(irf?.agreementDetails?.service?.type) ||
      isNullOrUndefiendOrEmpty(irf?.agreementDetails?.feeAmount))
  ) {
    return 2;
  }

  //Leasing case
  if (irf?.wipType === "LEASING" && isNullOrUndefiendOrEmpty(irf?.secondParty)) {
    return 1;
  }
  if (isNullOrUndefiend(irf?.agreementDetails?.feeAmount) || irf?.agreementDetails?.feeAmount === 0) {
    return 2;
  }
  //fixed rent case
  if (
    irf?.agreementDetails?.serviceFeeCalculationBasis1 === FEE_CALCULATION_BASE_1["FIXED_RENT"] &&
    (isNullOrUndefiendOrEmpty(irf?.agreementDetails?.service?.type) ||
      isNullOrUndefiendOrEmpty(irf?.agreementDetails?.feeAmount) ||
      isNullOrUndefiendOrEmpty(irf?.agreementDetails?.rent?.value) ||
      isNullOrUndefiendOrEmpty(irf?.agreementDetails?.noOfDays))
  ) {
    return 2;
  }

  // Pure revenue sharing
  if (
    irf?.agreementDetails?.serviceFeeCalculationBasis2 === PURE_REV_SHARING_OPTIONS[1] &&
    (isNullOrUndefiendOrEmpty(irf?.agreementDetails?.service?.type) ||
      isNullOrUndefiendOrEmpty(irf?.agreementDetails?.feeAmount) ||
      isNullOrUndefiendOrEmpty(irf?.agreementDetails?.securityDeposit) ||
      isNullOrUndefiendOrEmpty(irf?.agreementDetails?.noOfDays))
  ) {
    return 2;
  }

  //Staggered rent
  if (
    irf?.agreementDetails?.serviceFeeCalculationBasis2 === STAGGERED_RENT_OPTIONS[1] &&
    (isNullOrUndefiendOrEmpty(irf?.agreementDetails?.service?.type) ||
      isNullOrUndefiendOrEmpty(irf?.agreementDetails?.feeAmount) ||
      isNullOrUndefiendOrEmpty(irf?.agreementDetails?.rent?.value) ||
      isNullOrUndefiendOrEmpty(irf?.agreementDetails?.noOfDays))
  ) {
    return 2;
  }

  //Third Party Objtec
  if (
    !isObjectNullOrUndefiendOrEmpty(irf?.agreementDetails?.thirdParty) &&
    isNullOrUndefiendOrEmpty(irf?.agreementDetails?.thirdParty?.feeAmount) &&
    isNullOrUndefiendOrEmpty(irf?.agreementDetails?.thirdParty?.name) &&
    Number(irf?.agreementDetails?.feeAmount || 0) <= (irf?.agreementDetails?.invoiceableAmount || 0)
  ) {
    return 2;
  }
  //commissions Array
  const commissions = irf?.agreementDetails?.commissions;
  if (commissions?.length !== 0) {
    let sum = 0;
    let error = false;
    for (let i = 0; i < (commissions?.length || 0); i++) {
      if (
        isNullOrUndefiendOrEmpty(commissions?.[i]?.userName) ||
        isNullOrUndefiendOrEmpty(commissions?.[i]?.weightage) ||
        isNullOrUndefiendOrEmpty(commissions?.[i]?.profitCenter)
      ) {
        error = true;
      }
      sum += Number(commissions?.[i]?.weightage);
    }
    if (error || (isLead && sum > 100) || (!isLead && sum !== 100)) {
      return 2;
    }
  }
  //credit note case
  const creditNoteType = irf?.creditRequestType;
  const isCreditNote = !isNullOrUndefiendOrEmpty(creditNoteType);

  if (isCreditNote) {
    if (
      creditNoteType === creditNoteEnum?.["CREDIT_NOTE"] &&
      Number(irf?.agreementDetails?.feeAmount || 0) > Number(irf?.agreementDetails?.originalFeeAmount || 0)
    ) {
      return 2;
    }
  }
  if (documents) {
    for (let i = 0; i < documents?.length; i++) {
      const doc = documents[i];
      if (doc?.documentStructureId === "other-documents") {
        continue;
      } else {
        if (!doc?.documentURLs?.length) {
          return 3;
        }
      }
    }
  }
  return false;
};

export const RequiredSelectBoxNode = (
  fieldName: string,
  label: string,
  options: optionProps[],
  initialValue?: string,
  rules?: Rule[],
  outerContainerClassName?: string,
  disabled?: boolean
) => ({
  type: nodeType.input,
  elementData: {
    inputType: inputType.selectBox,
    formItemProps: {
      name: fieldName,
      rules: [{ required: true, message: ValidationErrors.REQUIRED_FIELD }, ...(rules || [])],
      initialValue,
      disabled,
    },
    inputProps: {
      type: ArInputType.text,
      label,
      required: true,
      options,
      outerContainerClassName,
      disabled,
    },
  },
});

export const ModalFormEditFlowInfoBar = ({ heading, body }: { heading?: ReactNode; body?: ReactNode }) => (
  <div className="py-8">
    <ArTag type="full-width" varient="warning">
      <div className="d-flex">
        <WarningOutlined className={"iconSize24"} />
        <div className="pl-8">
          <p className="sub-hero-text">{heading || STRING_CONSTANTS.CHANGES_WILL_APPLY_TO_FUTURE}</p>
          <p className="sub-hero-text-regular-medium">{body || STRING_CONSTANTS.EXISTED_UNCHANGED}</p>
        </div>
      </div>
    </ArTag>
  </div>
);
