export enum HEADER_TAB {
  OVERVIEW = "overview",
  OVERVIEW_LABEL = "Overview",
  PROPERTIES = "properties",
  PROPERTIES_LABEL = "Properties",
  REQUIREMENT = "requirement",
  REQUIREMENT_LABEL = "Requirements",
  MARKER = "marker",
  MARKER_LABEL = "Marker",
  ANALYTICS = "analytics",
  ANALYTICS_LABEL = "Analytics",
  WIP_LISTING = "wip",
  WIP_LISTING_LABEL = "WIP",
  REVENUE = "revenue",
  REVENUE_LABEL = "Revenue",
  REVENUE_REPORT = "Revenue Report",
  COLLECTIONS = "collections",
  COLLECTIONS_LABEL = "Collections",
  INVOICES = "invoices",
  INVOICES_LABEL = "Invoices",
  BILLING = "billing",
  BILLING_LABEL = "Billing",
  INVOICES_REQ_LABEL = "Invoices Request",
  PAYMENTS = "payments",
  PAYMENTS_LABEL = "Payments Claim",
  CONSULTING_LABEL = "Consulting",
  LEASING_LABEL = "Leasing",
  INTEL_DATA = "intel-data",
  INTEL_DATA_LABEL = "Intel Data",
  COLLECTIONS_NEW = "Collections",
  COLLECTION_REPORT = "Collection Report",
}

export const MARKER_KEYS = {
  MARKER: "MARKER",
};

export const BRAND_MANAGER = {
  ACCESS_KEY: "BRAND_MANAGER_PANEL",
};

export const HeaderPropertiesOptions = {
  key: HEADER_TAB.PROPERTIES,
  label: HEADER_TAB.PROPERTIES_LABEL,
};

export const HeaderRequirementsOptions = {
  key: HEADER_TAB.REQUIREMENT,
  label: HEADER_TAB.REQUIREMENT_LABEL,
};

export const HeaderMarkerOptions = {
  key: HEADER_TAB.MARKER,
  label: HEADER_TAB.MARKER_LABEL,
};
export const HeaderIntelDataOptions = {
  key: HEADER_TAB.INTEL_DATA,
  label: HEADER_TAB.INTEL_DATA_LABEL,
};

export const HeaderWipOptions = {
  key: HEADER_TAB.WIP_LISTING,
  label: HEADER_TAB.WIP_LISTING_LABEL,
};

export const HeaderCollectionsOptions = {
  key: HEADER_TAB.COLLECTIONS,
  label: HEADER_TAB.COLLECTIONS_LABEL,
};

export const HeaderInvoicesOptions = {
  key: HEADER_TAB.INVOICES,
  label: HEADER_TAB.INVOICES_LABEL,
};

export const HeaderPaymentOptions = {
  key: HEADER_TAB.PAYMENTS,
  label: HEADER_TAB.PAYMENTS_LABEL,
};

export const AnalyticsOptions = [
  {
    key: HEADER_TAB.ANALYTICS,
    label: HEADER_TAB.ANALYTICS_LABEL,
  },
];

export enum OPTION_LIST_TAB {
  OPTION_LIST = "optionList",
  OPTION_LIST_LABEL = "Option List",
  MATCHING_LIST = "matchingProperties",
  MATCHING_LIST_LABEL = "Matching Properties",
  DRAFT = "draftProperties",
  DRAFT_LABEL = "Draft Properties",
}

export enum PROPERTIES_TAB {
  NEWLY_CREATED_LABEL = "Newly Created Shops",
  UPDATED_EXPIRED_SHOPS_LABEL = "Updated/Expired Shops",
  NEWLY_CREATED_KEY = "new",
  UPDATED_EXPIRED_SHOPS_KEY = "previous",
}

export enum USER_MODULES {
  ANALYTICS_BM = "ANALYTICS_BM",
  ANALYTICS_SURVEYOR = "ANALYTICS_SURVEYOR",
  ANALYTICS_WIP = "ANALYTICS_WIP",
  REQUIREMENTS = "REQUIREMENTS",
  PROPERTIES = "PROPERTIES",
  WIP = "WIP",
  MARKER = "MARKER",
  SURVEYOR_PANEL = "SURVEYOR_PANEL",
  REVENUE_SHEET_PREVIEW = "REVENUE_SHEET_PREVIEW",
  INVOICE_SHEET_PREVIEW = "INVOICE_SHEET_PREVIEW",
  COLLECTIONS = "COLLECTIONS",
  ANALYTICS_COLLECTIONS = "ANALYTICS_COLLECTIONS",
  ANALYTICS_COLLECTIONS_DTLS = "ANALYTICS_COLLECTIONS_DTLS",
  COLLECTION_PREVIEW = "COLLECTION_PREVIEW",
  INVOICES = "INVOICES",
  PAYMENT_CLAIM = "PAYMENT_CLAIM",
  INTEL_DATA = "INTEL_DATA",
  INVOICES_PREVIEW = "INVOICES_PREVIEW",
  REVENUE_REPORT = "REVENUE_REPORT",
  COLLECTION_REPORT = "COLLECTION_REPORT",
}

export enum USER_ROLE_NAME {
  BRAND_MANAGER_ASSOCIATE = "BRAND_MANAGER_ASSOCIATE",
  BRAND_MANAGER_ASSOCIATE_MARKER = "BRAND_MANAGER_ASSOCIATE__MARKER",
  BRAND_MANAGER_LEAD = "BRAND_MANAGER_LEAD",
  BRAND_MANAGER_LEAD_MARKER = "BRAND_MANAGER_LEAD__MARKER",
  CENTRAL_TEAM = "CENTRAL_TEAM",
  MARKER = "MARKER",
  TECH_BRAND_MANAGER = "TECH_BRAND_MANAGER",
  FINANCE = "FINANCE",
  ASSOCIATE_BRAND_MANAGER_LEAD = "ASSOCIATE_BRAND_MANAGER_LEAD",
}
