import {
  AnalyticsDropDown,
  BillingDropDown,
  CollectionOptionsDropDown,
  RevenueOptionsDropDown,
  WIPOptionsDropDown,
} from "components/appLayout/partials";
import { APP_ROUTES } from "constants/Routes";
import {
  HEADER_TAB,
  HeaderCollectionsOptions,
  HeaderIntelDataOptions,
  HeaderInvoicesOptions,
  HeaderMarkerOptions,
  HeaderPaymentOptions,
  HeaderPropertiesOptions,
  HeaderRequirementsOptions,
  USER_MODULES,
  USER_ROLE_NAME,
} from "constants/Taboptions";
import React from "react";

import { isNullOrUndefiend } from "./helperFunctions";

export const getUserRoleAccess = (userModules?: string[]) => {
  const hasAccessToAgentAnalytics = userModules?.includes(USER_MODULES.ANALYTICS_BM);

  const hasAccessToSurvAnalytics = userModules?.includes(USER_MODULES.ANALYTICS_SURVEYOR);

  const hasAccessWIPAnalytics = userModules?.includes(USER_MODULES.ANALYTICS_WIP);

  const hasAccessToProperties = userModules?.includes(USER_MODULES.PROPERTIES);
  const hasAccessToRequirements = userModules?.includes(USER_MODULES.REQUIREMENTS);

  const hasAccessToWIP = userModules?.includes(USER_MODULES.WIP);

  const hasAccessToMarker = userModules?.includes(USER_MODULES.MARKER);

  const hasAccessToRevenueSheetPreview = userModules?.includes(USER_MODULES.REVENUE_SHEET_PREVIEW);

  const hasAccessToInvoiceSheetPreview = userModules?.includes(USER_MODULES.INVOICE_SHEET_PREVIEW);

  const hasAccessToCollections = userModules?.includes(USER_MODULES.COLLECTIONS);
  const hasAccessToCollectionsReport = userModules?.includes(USER_MODULES.COLLECTION_REPORT);
  const hasAccessToRevenueReport = userModules?.includes(USER_MODULES.REVENUE_REPORT);
  const hasAccessToCollectionsAnanlytics = userModules?.includes(USER_MODULES.ANALYTICS_COLLECTIONS);
  const hasAccessToCollectionsAnalyticsDetails = userModules?.includes(USER_MODULES.ANALYTICS_COLLECTIONS_DTLS);
  const hasAccessToCollectionsPreview = userModules?.includes(USER_MODULES.COLLECTION_PREVIEW);
  const hasAccessToInvoices = userModules?.includes(USER_MODULES.INVOICES);
  const hasAccessToInvoicesPreview = userModules?.includes(USER_MODULES.INVOICES_PREVIEW);

  const hasAccessToPayments = userModules?.includes(USER_MODULES.PAYMENT_CLAIM);

  const hasAccessToIntelData = userModules?.includes(USER_MODULES.INTEL_DATA);

  return {
    hasAccessToAgentAnalytics,
    hasAccessToMarker,
    hasAccessToProperties,
    hasAccessToRequirements,
    hasAccessToSurvAnalytics,
    hasAccessToWIP,
    hasAccessWIPAnalytics,
    hasAccessToRevenueSheetPreview,
    hasAccessToInvoiceSheetPreview,
    hasAccessToCollections,
    hasAccessToCollectionsAnalyticsDetails,
    hasAccessToCollectionsAnanlytics,
    hasAccessToCollectionsPreview,
    hasAccessToInvoicesPreview,
    hasAccessToInvoices,
    hasAccessToPayments,
    hasAccessToIntelData,
    hasAccessToCollectionsReport,
    hasAccessToRevenueReport,
  };
};

export const getHeaderForUserModules = (userModules?: string[], roleName?: USER_ROLE_NAME) => {
  const headers = [];

  const {
    hasAccessToAgentAnalytics,
    hasAccessToMarker,
    hasAccessToProperties,
    hasAccessToRequirements,
    hasAccessToSurvAnalytics,
    hasAccessWIPAnalytics,
    hasAccessToCollections,
    hasAccessToCollectionsAnanlytics,
    hasAccessToInvoices,
    hasAccessToPayments,
    hasAccessToWIP,
    hasAccessToRevenueReport,
    hasAccessToIntelData,
    hasAccessToCollectionsReport,
  } = getUserRoleAccess(userModules);

  if (hasAccessToProperties) {
    headers.push(HeaderPropertiesOptions);
  }
  if (hasAccessToRequirements) {
    headers.push(HeaderRequirementsOptions);
  }
  if (hasAccessToWIP) {
    headers.push({
      key: HEADER_TAB.WIP_LISTING,
      label: <WIPOptionsDropDown />,
    });
  }

  if (roleName === USER_ROLE_NAME.FINANCE) {
    if (hasAccessToInvoices && hasAccessToRevenueReport) {
      headers.push({
        key: HEADER_TAB.REVENUE,
        label: <RevenueOptionsDropDown />,
      });
    } else if (hasAccessToInvoices) {
      headers.push(HeaderInvoicesOptions);
    } else if (hasAccessToRevenueReport) {
      //not required right now
    }

    if (hasAccessToCollections && hasAccessToCollectionsReport) {
      headers.push({
        key: HEADER_TAB.COLLECTIONS_NEW,
        label: <CollectionOptionsDropDown />,
      });
    } else if (hasAccessToCollections) {
      headers.push(HeaderCollectionsOptions);
    } else if (hasAccessToCollectionsReport) {
      //not req right now
    }
    if (hasAccessToPayments) {
      headers.push(HeaderPaymentOptions);
    }
  } else {
    headers.push({
      key: HEADER_TAB.BILLING,
      label: (
        <BillingDropDown
          showPayments={hasAccessToPayments}
          showCollections={hasAccessToCollections}
          showInvoicesRequest={hasAccessToInvoices}
        />
      ),
    });
  }

  if (
    hasAccessToAgentAnalytics ||
    hasAccessToSurvAnalytics ||
    hasAccessWIPAnalytics ||
    hasAccessToCollectionsAnanlytics
  ) {
    headers.push({
      key: HEADER_TAB.ANALYTICS,
      label: (
        <AnalyticsDropDown
          showAgentAnalytics={hasAccessToAgentAnalytics}
          showSurvAnalytics={hasAccessToSurvAnalytics}
          hasAccessWIPAnalytics={hasAccessWIPAnalytics}
          hasAccessCollectionAnalytics={hasAccessToCollectionsAnanlytics}
        />
      ),
    });
  }
  if (hasAccessToMarker) {
    headers.push(HeaderMarkerOptions);
  }
  if (hasAccessToIntelData) {
    headers.push(HeaderIntelDataOptions);
  }
  return headers;
};

//if user has access to both collections and invoices then we will make routes accordingly
export const getBillingRoutes = (route: string, roleName?: USER_ROLE_NAME) => {
  if (isNullOrUndefiend(roleName)) {
    return "";
  }
  if (route === APP_ROUTES.COLLECTIONS) {
    if (roleName !== USER_ROLE_NAME.FINANCE) {
      return `${APP_ROUTES.BILLING}${APP_ROUTES.COLLECTIONS}/${APP_ROUTES.LISTING}`;
    } else if (roleName === USER_ROLE_NAME.FINANCE) {
      return `${HEADER_TAB.COLLECTIONS_NEW}${APP_ROUTES.COLLECTIONS}/${APP_ROUTES.LISTING}`;
    } else {
      return `${APP_ROUTES.COLLECTIONS}/${APP_ROUTES.LISTING}`;
    }
  } else if (route === APP_ROUTES.INVOICES) {
    if (roleName !== USER_ROLE_NAME.FINANCE) {
      return `${APP_ROUTES.BILLING}${APP_ROUTES.INVOICES}/${APP_ROUTES.LISTING}`;
    } else if (roleName === USER_ROLE_NAME.FINANCE) {
      return `${APP_ROUTES.REVENUE}${APP_ROUTES.INVOICES}/${APP_ROUTES.LISTING}`;
    } else {
      return `${APP_ROUTES.INVOICES}/${APP_ROUTES.LISTING}`;
    }
  } else if (route === APP_ROUTES.PAYMENTS) {
    if (roleName !== USER_ROLE_NAME.FINANCE) {
      return `${APP_ROUTES.BILLING}${APP_ROUTES.PAYMENTS}/${APP_ROUTES.LISTING}`;
    } else {
      return `${APP_ROUTES.PAYMENTS}/${APP_ROUTES.LISTING}`;
    }
  }
  return "";
};
